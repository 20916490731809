<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('chargePlace.cpTitle') }} {{ $t('common.edit') }}</div>
    </template>

    <!-- START Spinner -->
    <div class="spinner-zone" v-if="isLoading">
      <b-spinner variant="primary" style="width: 2.5rem; height: 2.5rem;" />
      <div>Loading...</div>
    </div>
    <!-- END Spinner -->

    <div class="row">
      <div class="col-xl-12">

        <!-- Station Info Start-->
        <div class="ion-modal-form-group-title">{{ $t('chargePlace.cpInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-name">
              <span>{{ $t('chargePlace.placeName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-update-name"
                          v-model="form.placeName"
                          :class="{ 'is-invalid': submitted && $v.form.placeName.$error }" />
            <div v-if="submitted && $v.form.placeName.$error" class="invalid-feedback">
              <span v-if="!$v.form.placeName.required">{{ $t('validation.cpNameIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-phone">
              <span>{{ $t('chargePlace.busiCall') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-update-phone"
                          v-model="form.busiCall"
                          :class="{ 'is-invalid': submitted && $v.form.busiCall.$error }" />
            <div v-if="submitted && $v.form.busiCall.$error" class="invalid-feedback">
              <span v-if="!$v.form.busiCall.required">{{ $t('validation.busiCallIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-8">
            <label class="form-label" for="cp-update-address">
              <span>{{ $t('chargePlace.address') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="d-flex">
              <b-form-input class="form-control"
                            type="text"
                            id="cp-update-address"
                            v-model="form.address"
                            :class="{ 'is-invalid': submitted && $v.form.address.$error }" />
              <button :disabled="$_utils.isEmpty(form.address)" type="button"
                      class="btn btn-outline-success text-nowrap"
                      @click="getGeoCodingData">{{ $t('chargePlace.inputLatLng') }}</button>
            </div>
            <div v-if="submitted && $v.form.address.$error" class="invalid-feedback">
              <span v-if="!$v.form.address.required">{{ $t('validation.addressIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-detail">{{ $t('chargePlace.detailLocation') }}</label>
            <b-form-input class="form-control" type="text" id="cp-update-detail" v-model="form.detailLocation" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-use-time">
              <span>{{ $t('chargePlace.useTime') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-update-use-time"
                          v-model="form.useTime"
                          :class="{ 'is-invalid': submitted && $v.form.useTime.$error }" />
            <div v-if="submitted && $v.form.useTime.$error" class="invalid-feedback">
              <span v-if="!$v.form.useTime.required">{{ $t('validation.useTimeIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-3">
            <label class="form-label" for="cp-update-lat">{{ $t('common.lat') }}</label>
            <b-form-input class="form-control" type="text" id="cp-update-lat" v-model="form.lat" />
          </div>
          <div class="col-xl-3">
            <label class="form-label" for="cp-update-lng">{{ $t('common.lng') }}</label>
            <b-form-input class="form-control" type="text" id="cp-update-lng" v-model="form.lng" />
          </div>
        </div>
        <!-- Station Info End-->

        <!-- File Upload Start -->
        <div class="row mb-3">
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-file1">{{ $t('common.file') }} 1</label>
            <template v-if="savedFile1">
              <div class="hstack gap-3">
                <input class="form-control me-auto" type="text" v-model="savedFile1.orgName" disabled />
                <div class="vr"></div>
                <button type="button" class="btn btn-outline-danger text-nowrap" @click="savedFileDel(1)">Delete</button>
              </div>
            </template>
            <template v-else>
              <div class="d-flex">
                <input class="form-control" type="file" accept="image/*" id="cp-update-file1"
                       ref="chargePlaceFile1" @change="handleFileChange(1)" />
                <button v-if="file1" type="button" class="btn btn-outline-danger" @click="fileDel(1)">Delete</button>
              </div>
            </template>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-file2">{{ $t('common.file') }} 2</label>
            <template v-if="savedFile2">
              <div class="hstack gap-3">
                <input class="form-control me-auto" type="text" v-model="savedFile2.orgName" disabled />
                <div class="vr"></div>
                <button type="button" class="btn btn-outline-danger text-nowrap" @click="savedFileDel(2)">Delete</button>
              </div>
            </template>
            <template v-else>
              <div class="d-flex">
                <input class="form-control" type="file" accept="image/*"  id="cp-update-file2"
                       ref="chargePlaceFile2" @change="handleFileChange(2)" />
                <button v-if="file2" type="button" class="btn btn-outline-danger" @click="fileDel(2)">Delete</button>
              </div>
            </template>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-file3">{{ $t('common.file') }} 3</label>
            <template v-if="savedFile3">
              <div class="hstack gap-3">
                <input class="form-control me-auto" type="text" v-model="savedFile3.orgName" disabled />
                <div class="vr"></div>
                <button type="button" class="btn btn-outline-danger text-nowrap" @click="savedFileDel(3)">Delete</button>
              </div>
            </template>
            <template v-else>
              <div class="d-flex">
                <input class="form-control" type="file" accept="image/*" id="cp-update-file3"
                       ref="chargePlaceFile3" @change="handleFileChange(3)" />
                <button v-if="file3" type="button" class="btn btn-outline-danger" @click="fileDel(3)">Delete</button>
              </div>
            </template>
          </div>
        </div>
        <!-- File Upload End -->

        <!-- Plan Info Start -->
        <div class="ion-modal-form-group-title">
          {{ $t('chargePlace.planInfo') }}
          <span class="ps-2 font-size-14 fw-normal text-danger">* {{ $t('common.planWarning') }}</span>
        </div>
        <div class="row mb-3">
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-plan">
              <span>{{ $t('chargePlace.planName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="cp-update-plan"
                         v-model="plan"
                         label="text" track-by="value"
                         :options="planOptions"
                         :placeholder="$t('alert.selectPlan')"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false"
                         @input="setDefaultPlanInfo" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-plan-date">{{ $t('chargePlace.planDate') }}</label>
            <div class="row">
              <date-picker type="date" format="YYYY-MM-DD" id="valid-to-date"
                           v-model="form.planDate"
                           value-type="format" />
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-plan-edit-date">{{ $t('plan.planEditDate') }}</label>
            <div class="row">
              <date-picker type="date" format="YYYY-MM-DD" id="valid-to-date"
                           v-model="form.settleInfoUpdateTimestamp"
                           value-type="format" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-server-fee">{{ $t('chargePlace.serverUsageFee') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-update-server-fee"
                          v-model="form.serverUsageFee" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-settlement-rate">{{ $t('chargePlace.settleRate') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-update-settlement-rate"
                          v-model="form.settleRate" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-update-profit-dist-rate">{{ $t('chargePlace.profitDistribution') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-create-update-dist-rate"
                          :disabled="plan?.value === 10000000000224"
                          v-model="form.commissionRate" />
          </div>
        </div>
        <!-- Plan Info End -->

        <!-- Settlement Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('chargePlace.settlementInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-bank">{{ $t('chargePlace.bankName') }}</label>
            <b-form-input class="form-control" type="text" id="cp-update-bank" v-model="form.bank" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-settlement-day">{{ $t('chargePlace.settleDay') }}</label>
            <div class="row">
              <div class="col-2 text-center align-content-center">{{ $t('chargePlace.monthly') }}</div>
              <div class="col-4">
                <b-form-input class="form-control" type="text" id="cp-update-settlement-day" v-model="form.settleDay" />
              </div>
              <div class="col-6 text-start align-content-center">{{ $t('chargePlace.day') }}</div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-account-holder">{{ $t('chargePlace.accountHolder') }}</label>
            <b-form-input class="form-control" type="text" id="cp-create-account-holder" v-model="form.bankAccountHolder" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-account-number">{{ $t('chargePlace.accountNumber') }}</label>
            <b-form-input class="form-control" type="text" id="cp-create-account-number" v-model="form.bankAccountNo" />
          </div>
        </div>
        <!-- Settlement Info End -->

        <!-- Hq and CP Info Start -->
        <div class="ion-modal-form-group-title">
          {{ $_app.user.roles[0] === 'ADMIN' ? $t('chargePlace.hqAndCpManagerInfo') : $t('chargePlace.cpManagerInfo') }}
        </div>
        <div class="row">
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-hq">
              <span>{{ $t('common.headquarters') }}</span>
              <i v-if="$_app.user.roles[0] === 'ADMIN'" class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="cp-update-hq"
                         v-model="hqId"
                         label="text" track-by="value"
                         :options="hqOptions"
                         :placeholder="$t('alert.selectHq')"
                         selectLabel=""
                         selectedLabel=""
                         :deselectLabel="$t('common.unselect')"
                         :searchable="false" :allow-empty="true"
                         :class="{ 'is-invalid': submitted && $v.hqId.$error }"
                         :disabled="$_app.user.roles[0] !== 'ADMIN'" />
            <div v-if="submitted && $v.hqId.$error" class="invalid-feedback">
              <span v-if="!$v.hqId.required">{{ $t('validation.hqIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cp-update-hq">
              <span>{{ $t('chargePlace.cpManagerInfo') }}</span>
            </label>
            <!--    TODO: options 확인 필요 -->
            <multiselect id="cp-update-cp"
                         v-model="cpManagerId"
                         label="text" track-by="value"
                         :options="cbManagerOptions"
                         :placeholder="$t('alert.selectManager')"
                         selectLabel=""
                         selectedLabel=""
                         :deselectLabel="$t('common.unselect')"
                         :searchable="false" :allow-empty="true" />
          </div>
        </div>
        <!-- Hq and CP Info End -->

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';

export default {
  components: { Multiselect },
  props: {
    selectedId: {
      type: String
    }
  },
  data() {
    return {
      isLoading: true,
      visible: false,
      submitted: false,
      hqOptions: [],
      planOptions: [],
      cbManagerOptions: [],
      planDefaultInfos: null,
      item: null,
      form: {
        placeName: null,
        busiCall: null,
        address: null,
        detailLocation: null,
        useTime: null,
        lat: null,
        lng: null,
        planDate: null,
        serverUsageFee: null,
        settleRate: null,
        commissionRate: null,
        settleDay: null,
        bank: null,
        bankAccountHolder: null,
        bankAccountNo: null,
      },
      file1: null,
      file2: null,
      file3: null,
      savedFile1: null,
      savedFile2: null,
      savedFile3: null,
      hqId: null,
      cpManagerId: null,
      plan: null
    }
  },
  validations: {
    form: {
      placeName: { required },
      busiCall: { required },
      address: { required },
      useTime: { required },
    },
    hqId: { required }
  },
  methods: {
    show() {
      const vm = this;

      vm.isLoading = true;
      vm.getHqOptions();
      vm.getPlanOptions();
      vm.getCbManagerOptions();

      vm.visible = true;
    },
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });

        vm.getItem();
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    getPlanOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlacePlan/list';

      vm.planOptions = [];

      http.post(_url, {
        page: 1,
        limit: 10,
        searchConditions: [],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        vm.planDefaultInfos = res.data.items;
        res.data.items.forEach(item => {
          vm.planOptions.push({ text: item.planName, value: item.id });
        });
      }).catch(err => {
        console.error('Business Plan List Error :: ', err)
      });
    },
    getCbManagerOptions() {
      const vm = this;
      const _url = `/onmapi/view/Account/listByPlaceId/${this.selectedId}`

      http.get(_url).then(res => {
        vm.cbManagerOptions = res.data.items.map(ele =>
          ({ text: ele.name, value: ele.id })
        );
      }).catch(err => {
        console.error('Place Manager GET Error :: ', err)
      });
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/ChargePlace/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);

        if (vm.form.chargePlacePlan && vm.form.chargePlacePlan.hasOwnProperty('id')) {
          vm.plan = vm.planOptions.find(item => item.value === vm.form.chargePlacePlan.id);
        }

        if (vm.form.hqBusiness && vm.form.hqBusiness.hasOwnProperty('id')) {
          vm.hqId = vm.hqOptions.find(item => item.value === vm.form.hqBusiness.id);
        }

        if (vm.item.attachFile1) {
          vm.savedFile1 = vm.item.attachFile1;
        }
        if (vm.item.attachFile2) {
          vm.savedFile2 = vm.item.attachFile2;
        }
        if (vm.item.attachFile3) {
          vm.savedFile3 = vm.item.attachFile3;
        }

        if (vm.form.placeRepresentAccount && vm.form.placeRepresentAccount.hasOwnProperty('id')) {
          vm.cpManagerId = vm.cbManagerOptions.find(item => item.value === vm.form.placeRepresentAccount.id);
        }
      }).then(() => {
        vm.isLoading = false;
      }).catch((err) => {
        console.error('Charge Place Retrieve Error !!', err)
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        placeName: null,
        busiCall: null,
        address: null,
        detailLocation: null,
        useTime: null,
        lat: null,
        lng: null,
        planDate: null,
        serverUsageFee: null,
        settleRate: null,
        commissionRate: null,
        settleDay: null,
        bank: null,
        bankAccountHolder: null,
        bankAccountNo: null,
      };
      vm.hqOptions = [];
      vm.planOptions = [];
      vm.planDefaultInfos = null;

      vm.file1 = null;
      vm.file2 = null;
      vm.file3 = null;
      vm.savedFile1 = null;
      vm.savedFile2 = null;
      vm.savedFile3 = null;
      vm.hqId = null;
      vm.cpManagerId = null;
      vm.plan = null;

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    fileDel(fileNumber) {
      const vm = this;
      vm[`file${fileNumber}`] = null;
      this.$refs[`chargePlaceFile${fileNumber}`].value = null;
    },
    savedFileDel(fileNumber) {
      const vm = this;

      Swal.fire({
        title: i18n.t('alert.confirmDelete'),
        showCancelButton: true,
        confirmButtonText: i18n.t('btn.ok'),
        cancelButtonText: i18n.t('btn.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          vm.deleteImageFile(fileNumber);
        }
      });
    },
    deleteImageFile(fileNumber) {
      const vm = this;
      const _url = `/onmapi/view/ChargePlace/deleteFile?placeId=${this.selectedId}&fileId=${vm[`savedFile${fileNumber}`].fileId}`;

      http.delete(_url).then(res => {

        if (res.data.success === true) {
          vm[`savedFile${fileNumber}`] = null;
          vm[`file${fileNumber}`] = null;

          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.deleted'),
            confirmButtonText: i18n.t('btn.close')
          });
        }
      }).catch(err => {
        console.error('Charge Place Delete File Error :: ', err)
      });
    },
    handleFileChange(fileNumber) {
      const vm = this;
      vm[`file${fileNumber}`] = vm.$refs[`chargePlaceFile${fileNumber}`].files[0];
    },
    notBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    setDefaultPlanInfo() {
      const vm = this;
      const selectedPlan = vm.planDefaultInfos.find(ele => ele.id === vm.plan.value)

      vm.form = {
        ...vm.form,
        serverUsageFee : selectedPlan.serverUsageFee,
        settleRate : selectedPlan.settleRate,
        commissionRate : selectedPlan.profitDistRate,
      }
    },
    getGeoCodingData() {
      const vm = this;
      const _url =  `/onmapi/utils/geocoding/latlng?address=${vm.form.address}`;

      http.get(_url).then(res => {
        if (res) {
          const location = res.data.item;

          vm.form.lat = location.lat;
          vm.form.lng = location.lng;
        }

      }).catch(err => {
        console.error('Get GeoCoding Data Error :: ', err)
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.geocodingError'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/update';

      const _formData = new FormData();

      _formData.append('placeId', this.selectedId);
      _formData.append('placeName', vm.form.placeName);
      _formData.append('busiCall', vm.form.busiCall);
      _formData.append('address', vm.form.address);
      _formData.append('useTime', vm.form.useTime);

      const _formFields = [
        { key: 'detailLocation', value: vm.form.detailLocation },
        { key: 'lat', value: vm.form.lat },
        { key: 'lng', value: vm.form.lng },
        { key: 'serverUsageFee', value: vm.form.serverUsageFee },
        { key: 'settleRate', value: vm.form.settleRate },
        { key: 'commissionRate', value: vm.form.commissionRate },
        { key: 'settleDay', value: vm.form.settleDay },
        { key: 'bank', value: vm.form.bank },
        { key: 'bankAccountHolder', value: vm.form.bankAccountHolder },
        { key: 'bankAccountNo', value: vm.form.bankAccountNo }
      ];

      _formFields.forEach(field => {
        if (!this.$_utils.isEmpty(field.value)) {
          _formData.append(field.key, field.value);
        }
      });

      if (!this.$_utils.isEmpty(vm.plan)) {
        _formData.append('planId', vm.plan.value);
      }

      if (!this.$_utils.isEmpty(vm.form.planDate)) {
        _formData.append('planDate', vm.form.planDate + ' 00:00:00');
      }

      if (!this.$_utils.isEmpty(vm.hqId)) {
        _formData.append('hqId', vm.hqId.value);
      }

      if (!this.$_utils.isEmpty(vm.file1)) {
        _formData.append('file1', vm.file1);
      }

      if (!this.$_utils.isEmpty(vm.file2)) {
        _formData.append('file2', vm.file2);
      }

      if (!this.$_utils.isEmpty(vm.file3)) {
        _formData.append('file3', vm.file3);
      }

      if (!this.$_utils.isEmpty(vm.cpManagerId)) {
        _formData.append('placeRepresentAccountId', vm.cpManagerId.value);
      }

      /*for (const [key, value] of _formData.entries()) {
        console.log(key, value);
      };*/

      http.patch(_url, _formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Charge Place Update Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
