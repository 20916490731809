<template>
  <Layout>
    <section class="csms-charge-place">

      <!-- Charge Place Info Start -->
      <div v-if="item" class="row">
        <div class="col-xl-12">
          <div class="card shadow-none">
            <div class="card-body">

              <div class="row">
                <div class="col-xl-6">
                  <ItemProfileLayout :icon-class-name="'uil uil-building'">
                    <template>
                      <DividedItems class="mb-2" :items="[ item.placeName,  item.busiCall, item.useTime ]" :text-class="`fs-5 fw-bold`" />
                      <DividedItems :items="[ item.address,  item.detailLocation ]" :text-class="`text-muted fs-6`" />
                    </template>
                  </ItemProfileLayout>
                </div>

                <div class="col-xl-5">
                  <ItemProfileLayout :icon-class-name="'uil uil-user'" class="mb-3">
                    <template>
                      <DividedItems :items="[ item.chargePlacePlan ? item.chargePlacePlan.planName : $t('chargePlace.planUnselected') ]" :text-class="`text-muted fs-6`" />
                    </template>
                  </ItemProfileLayout>
                </div>

                <div class="col-xl-1">
                  <template v-if="$_app.user.roles[0] !== 'CP'">
                    <div class="float-end">
                      <b-button size="sm" variant="warning" @click="edit">
                        <i class="uil uil-edit me-2"></i>
                        <span>{{ $t('btn.edit') }}</span>
                      </b-button>
                    </div>
                  </template>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6">
                  <b-img :src="item.attachFile1.filePath"
                         rounded width="200" fluid alt="Responsive image"
                         v-if="item.attachFile1"
                         class="me-2"/>
                  <b-img :src="item.attachFile2.filePath"
                         rounded width="200" fluid alt="Responsive image"
                         v-if="item.attachFile2"
                         class="me-2"/>
                  <b-img :src="item.attachFile3.filePath"
                         rounded width="200" fluid alt="Responsive image"
                         v-if="item.attachFile3" />
                </div>
                <div class="col-xl-6">
                  <ItemProfileLayout :icon-class-name="'mdi mdi-currency-jpy'" :icon-size="'34px'">

                    <div v-if="item.paymentUnitPrice" class="badge rounded-pill badge-outline-primary font-size-12 mb-2">{{ item.paymentUnitPrice.priceName }}</div>
                    <BadgeTitleItems v-if="item.paymentUnitPrice"
                                     :items="[
                                       { title: $t('common.slow'), content: `${item.paymentUnitPrice.slowSellingPrice} ¥/kWh` },
                                       { title: $t('common.fast'), content: `${item.paymentUnitPrice.fastSellingPrice} ¥/kWh` }
                                     ]" />
                    <BadgeTitleItems v-else
                                     :items="[
                                       { title: $t('common.slow'), content: null },
                                       { title: $t('common.fast'), content: null }
                                     ]" />
                  </ItemProfileLayout>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Charge Place Info End -->

      <!-- Charge Box Start -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount"/>
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('chargePlace.noCb')"
                         :fields="fields" :items="cbItems">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`chargePlace.${data.column}`) }}</span>
                  </template>

                  <template #cell(chargerType)="data">
                    {{ $_formatter.toLowerCaseString(data.item.chargerType) === 'fast' ? $t('common.fast') : $t('common.slow') }}
                  </template>
                  <template #cell(maxKw)="data">
                    <span>{{ data.item.maxKw }}</span>
                  </template>
                  <template #cell(connectorCount)="data">
                    {{ data.item.connectors.length }}
                  </template>
                  <template #cell(cbType)="data">
                    <template v-if="data.item.connectors && data.item.connectors.length > 0">
                      <div v-for="item in data.item.connectors" :key="item.id">
                        <div>
                          <span>{{ item.connectorType }}</span>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template #cell(connectorStatus)="data">
                    <template v-if="data.item.connectors && data.item.connectors.length > 0">
                      <div v-for="item in data.item.connectors" :key="item.id">
                        <div>
                          <span class="pe-2">{{ item.connectorLinkedId }}</span>
                          [<span :class="$_formatter.cbStatusTextColor($_app.code.chargePointStatus[item.status] ?? item.status)">{{ $_app.code.chargePointStatus[item.status] ? item.status : '-' }}</span>]
                        </div>
                      </div>
                    </template>
                  </template>
                  <template #cell(installDate)="data">
                    {{ data.item.installationDate ? data.item.installationDate : '-' }}
                  </template>
                  <template #cell(created)="data">
                    <template v-if="data.item.connectors && data.item.connectors.length > 0">
                      <div v-for="item in data.item.connectors" :key="item.id">
                        <div>
                          <span>{{ item.created }}</span>
                        </div>
                      </div>
                    </template>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Charge Box End -->

      <!-- Bottom Button Start -->
      <div class="row mb-3">
        <div class="col-xl-6">
          <div class="float-start" v-if="$_app.user.roles[0] !== 'CP'">
            <b-button variant="danger" @click="del">
              <i class="uil uil-minus me-2"></i>
              <span>{{ $t('btn.cpDelete') }}</span>
            </b-button>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="float-end">
            <b-button variant="outline-secondary" @click="$router.go(-1)">
              <i class="uil uil-list-ul me-2"></i>
              <span>{{ $t('btn.list') }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <!-- Bottom Button End -->

      <edit-modal v-if="item" ref="cpEditModal" :selected-id="selectedId" @callback="getInfoItem" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/Main.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import ItemProfileLayout from '@/views/components/ItemProfileLayout.vue';
import DividedItems from '@/views/components/DividedItems.vue';
import BadgeTitleItems from "@/views/components/BadgeTitleItems.vue";
import EditModal from '@/views/pages/charge-place/CpEditModal.vue';

export default {
  components: {
    Layout, PerPageSelector, TotalCount, ItemProfileLayout, DividedItems, BadgeTitleItems, EditModal
  },
  data() {
    return {
      selectedId: null,
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      item: null,
      cbItems: null,
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'chargeBoxName', label: '충전기명', class: 'text-center' },
        { key: 'chargeBoxId', label: '충전기 ID', class: 'text-center' },
        { key: 'chargerType', label: '충전 유형', class: 'text-center' },
        { key: 'maxKw', label: '충전 속도', class: 'text-center' },
        { key: 'connectorCount', label: '충전구 수', class: 'text-center' },
        { key: 'cbType', label: '충전기 유형', class: 'text-center' },
        { key: 'connectorStatus', label: '충전구 ID [상태]', class: 'text-center' },
        { key: 'installDate', label: '설치일', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' },
      ]
    }
  },
  created() {
    const vm = this;
    vm.selectedId = this.$route.params.id;
  },
  mounted() {
    const vm = this;

    vm.showOverlay();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    async getItems() {
      const vm = this;

      await Promise.all([
        vm.getInfoItem(),
        vm.getCbItems()
      ]);
      vm.hideOverlay();
    },
    getInfoItem() {
      const vm = this;
      const _url = `/onmapi/view/ChargePlace/retrieve/${vm.selectedId}`;

      return new Promise((resolve, reject) => {
        http.get(_url).then((res) => {
          vm.item = res.data.item;
          resolve(res);
        }).catch((err) => {
          console.error('Charge Place Retrieve Error !!', err)
          reject(err);
        });
      });
    },
    getCbItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/list';

      return new Promise((resolve, reject) => {
        http.post(_url, {
          page: vm.pagination.currentPage,
          limit: vm.pagination.perPage,
          searchConditions: [{
            'searchKey': 'placeId',
            'searchValue': vm.selectedId
          }],
          sortCondition: { sortDirection: 'DESC' }
        }).then(res => {
          vm.cbItems = res.data.items;
          vm.pagination.totalCount = res.data.totalCount;
          resolve(res);
        }).catch(err => {
          console.error('Charge Box List Error :: ', err)
          reject(err);
        });
      });
    },
    edit() {
      const vm = this;

      vm.$nextTick(() => {
        this.$refs.cpEditModal.show();
      });
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getCbItems();
        vm.hideOverlay();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.showOverlay();
        await vm.getCbItems();
        vm.hideOverlay();
      }
    },
    del() {
      const vm = this;

      if (vm.cbItems.length > 0) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.haveCbNoDelete'),
          confirmButtonText: i18n.t('btn.close')
        });
        return false;
      } else {
        Swal.fire({
          title: i18n.t('alert.confirmDelete'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.delItem();
          }
        });
      }
    },
    delItem() {
      const vm = this;
      const _url = `/onmapi/view/ChargePlace/delete/${vm.selectedId}`;

      http.delete(_url).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.deleted'),
            confirmButtonText: i18n.t('btn.close')
          });
          this.$router.go(-1);
        }
      }).catch(err => {
        console.error('Charge Place Delete Error :: ', err);

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>
